<template>
  <div class="travel-todo-list">
    <van-tabs v-model="activeName" @click="onClick" sticky>
      <van-tab title="待处理推送" name="a" style="overflow-y: auto;">
        <!-- 列表-->
        <div ref="fresh" :style="{height: freshHeight +'px'}">
          <div v-if="list.length<=0">
            <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
          </div>
          <div v-else>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh()">
              <van-list
                  v-model="loading"
                  loading-text="加载中"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="nextPage"
                  style="margin-bottom: 45px"
              >
                <travel-todo-cell v-for="item in list" v-bind:key="item.id" :data="item"/>

              </van-list>
            </van-pull-refresh>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import TravelTodoCell from '@/components/TravelTodoCell/index'
import {Toast} from "vant";

export default {
  name: "BulkCrmsTravelTodoList",
  components: {CrmTabBar, TravelTodoCell},
  data() {
    return {
      activeName: '',
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      list: [],
      currentPage: 1, // 当前页码
      pageSize: 25, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.onRefresh()
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    // 下拉刷新
    getData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.loading = true
      this.$ajax.post('/api/ajax/planVisit/getBulkCrmPlanvisitFromGoTodo.json',
          {
            createdPeople: this.$store.getters.userId,
            current: this.currentPage,
            size: this.pageSize,
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          console.log(res.data)
          res.data.forEach(item => {
            this.list.push(item)
          })
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.data.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].to = '/FrequencyRemindPhone/' + this.list[i].travelPlanid
            this.list[i].detail = '点击处理'
          }
          Toast.success('加载成功');
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    onClick(name, title) {

    },
  }
}
</script>

<style>

.travel-todo-list {
  background-color: #FFFFFF;
}

.travel-todo-list .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}
</style>